import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";

import desk from "../../static/desk.jpg";
import profileImg from "../../static/profile.png";

const HeroImage = styled.div`
  background-image: url(${desk});
  background-size: cover;
  background-position: center 43%;
  min-height: 60vh;
  position: relative;

  @media (max-width: ${props => props.theme.breakpoints.phone}) {
    min-height: 50vh;
  }

  &::after {
    content: " ";
    background: linear-gradient(
      to bottom right,
      ${props => props.theme.colors.secondary},
      white
    );
    opacity: 0.3;
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
    width: 100%;
  }
`;

const Container = styled.header`
  display: grid;
  grid-template-columns: 1fr 2fr;

  @media (max-width: ${props => props.theme.breakpoints.tablet}) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: ${props => props.theme.breakpoints.phone}) {
    grid-template-columns: 1fr;
  }
`;

const Content = styled.div`
  padding: 2em;

  @media (max-width: ${props => props.theme.breakpoints.phone}) {
    text-align: center;
  }

  h1 {
    margin: 0.5em 0 0;
  }
  p {
    margin: 0;
    font-size: 1.2em;
  }
`;

const ProfileImg = styled.img`
  border-radius: 100%;
  height: 100px;
  width: 100px;
`;

export const HeroContent = () => (
  <Content>
    <ProfileImg
      src={profileImg}
      alt="An extremely cute and charming sketch portrait of me!"
    />
    <Link to="/">
      <h1>Renée Blackburn</h1>
    </Link>
    <p>Software Engineer in Toronto, Canada</p>
  </Content>
);

export const Hero = () => (
  <Container>
    <HeroContent />
    <HeroImage />
  </Container>
);
