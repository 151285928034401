import React from "react";
import styled from "styled-components";
import theme from "../../config/theme";
import { lighten } from "polished";

const TileContainer = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto;

  @media (max-width: ${props => props.theme.breakpoints.tablet}) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: ${props => props.theme.breakpoints.phone}) {
    grid-template-columns: 1fr;
  }
`;

const SingleTile = styled.div`
  border: 1em solid ${props => props.bgcolor};
  background: ${props => lighten(0.14, props.bgcolor)};
  line-height: 1.6;
  padding: 2em;

  a {
    font-weight: 500;
    color: inherit;
    text-decoration: underline;
    text-decoration-thickness: 4px;
    text-decoration-color: ${props => props.bgcolor};
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  li {
    padding: 0.15em;
  }
  p {
    margin: 0;
  }
`;

const TileTitle = styled.h2`
  font-size: 1.5em;
  margin: 0;
  text-transform: lowercase;
`;

const Tile = ({ title, children, bgcolor }) => (
  <SingleTile bgcolor={bgcolor}>
    <TileTitle bgcolor={bgcolor}>{title}</TileTitle>

    {children}
  </SingleTile>
);

export const Tiles = () => {
  const { colors } = theme;
  return (
    <TileContainer>
      <Tile title="Who I Am" bgcolor={colors.secondary}>
        <p>
          I like video games, illustration, and list-making. I believe the web
          is for everyone and everyone should be part of making it. Inclusivity
          in tech is important to me. Asking "why" is my superpower.
        </p>
      </Tile>

      <Tile title="What I Do" bgcolor={colors.accent}>
        <p>
          I've been in the industry since 2012. I focus on the front-end and
          dabble in everything else. I don't believe in ninjas and rockstars,
          but I do believe in supportive, collaborative and structured
          environments and try to make them wherever I go.
        </p>
      </Tile>

      <Tile title="Stuff I've Done" bgcolor={colors.tertiary}>
        <ul>
          <li>
            <a
              href="https://github.com/renvrant/gatsby-mdx-netlify-cms-starter"
              target="_blank"
            >
              Gatsby + MDX + Netlify CMS Starter
            </a>
          </li>
          <li>
            <a href="https://artscience.ca/work/nifty-fish/" target="_blank">
              NiftyFish
            </a>
          </li>
          {/* <li><a href="http://renvrant.com/tarot-me" target="_blank">Tarot Me!</a></li> */}
          <li>
            <a
              href="https://fitc.ca/presentation/composing-components-in-angular/"
              target="_blank"
            >
              Composing Components in Angular
            </a>{" "}
            <cite>FITC Web Unleashed 2018</cite>
          </li>
          <li>
            <a href="https://2017.ng-conf.org/sessions/formcontrol-freaks-redux-edition/" target="_blank">
              FormControl Freaks: Redux Edition
            </a>{" "}
            <cite>ng-conf 2017</cite>
          </li>
        </ul>
      </Tile>

      <Tile title="Tools I Use" bgcolor={colors.accent}>
        <p>
          Modern JavaScript Frameworks (React, Vue, etc), Gatsby, TypeScript,
          Apollo, Redux, Design Systems, Agile Processes, CI/CD
        </p>
      </Tile>

      <Tile title="Social" bgcolor={colors.tertiary}>
        <ul>
          <li>
            <a href="https://www.github.com/renvrant">Github</a>
          </li>
          <li>
            <a href="http://codepen.io/renvrant/">CodePen</a>
          </li>
          <li>
            <a href="https://www.linkedin.com/in/renvrant">LinkedIn</a>
          </li>
          <li>
            <a href="http://www.twitter.com/renvrant">Twitter</a>
          </li>
        </ul>
      </Tile>

      <Tile title="What's a renvrant?" bgcolor={colors.secondary}>
        <p>
          My old name was <b>Ren</b>ee <b>Vrant</b>sidis and I like{" "}
          <a href="https://en.wikipedia.org/wiki/Rembrandt" title="Rembrandt">
            impressionism
          </a>{" "}
          and{" "}
          <a href="https://en.wikipedia.org/wiki/Revenant" title="Revenant">
            ghosts
          </a>
          .
        </p>
      </Tile>
    </TileContainer>
  );
};
