import { lighten } from 'polished'

const colors = {
  primary: '#F97171', // Color for buttons or links
  primaryLight: '#F99192',
  bg: 'white', // Background color
  grey: {
    dark: 'rgba(0, 0, 0, 0.7)',
    default: 'rgba(0, 0, 0, 0.6)',
    light: 'rgba(0, 0, 0, 0.4)',
    ultraLight: 'rgba(0, 0, 0, 0.25)',
  },
  white: 'white',
  secondary: '#B2EEE6',
  secondaryLight: lighten(0.2, '#B2EEE6'),
  tertiary: '#AAE4F6',
  tertiaryLight: lighten(0.2, '#AAE4F6'),
  accent: '#dbc9e8',
  accentLight: lighten(0.2, '#dbc9e8'),
}

const transitions = {
  normal: '0.5s',
}

const fontSize = {
  small: '0.9rem',
}

const fontFamily = {
  serif: `'Courier Prime', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Helvetica', 'Arial', serif`,
  sansSerif: `'Quicksand', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Helvetica', 'Arial', sans-serif`,
}

const breakpoints = {
  tablet: '1200px',
  phone: '600px',
}

const theme = {
  colors,
  transitions,
  fontSize,
  breakpoints,
  fontFamily,
  maxWidth: '1000px',
  baseFontSize: '18px',
}


export default theme
